var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"flat":"","fixed":"","elevate-on-scroll":""}},[_c('v-toolbar-title',[_vm._v("Cabaret Flamenco de Montréal")]),_c('v-spacer'),(_vm.$vuetify.theme.dark)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":""},on:{"click":_vm.changeLightTheme}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-weather-sunny ")])],1)]}}],null,false,4140408901)},[_c('span',[_vm._v(_vm._s(_vm.$t("navbar.light")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":""},on:{"click":_vm.changeDarkTheme}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-weather-night")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("navbar.dark")))])]),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("language")]),_vm._v(" "+_vm._s(_vm.$i18n.locale)+" ")],1)]}}])},[_c('v-list',{staticStyle:{"cursor":"pointer"},attrs:{"rounded":""}},[_c('v-list-item-group',_vm._l((_vm.languages),function(lang,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',{on:{"click":function($event){$event.stopPropagation();return _vm.switchLang(lang.code)}}},[_c('v-list-item-title',[_vm._v(_vm._s(lang.title))])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }