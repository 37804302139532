<template>
  <v-app-bar flat fixed elevate-on-scroll>
    <v-toolbar-title>Cabaret Flamenco de Montréal</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tooltip v-if="$vuetify.theme.dark" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="changeLightTheme"
          class="mr-4"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-weather-sunny </v-icon>
        </v-btn>
      </template>
      <span>{{ $t("navbar.light") }}</span>
    </v-tooltip>
    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="changeDarkTheme"
          class="mr-4"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-weather-night</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("navbar.dark") }}</span>
    </v-tooltip>
    <v-menu transition="slide-y-transition" bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>language</v-icon>
          {{ $i18n.locale }}
        </v-btn>
      </template>
      <v-list rounded style="cursor:pointer">
        <v-list-item-group>
          <v-list-item v-for="(lang, i) in languages" :key="i">
            <v-list-item-content @click.stop="switchLang(lang.code)">
              <v-list-item-title>{{ lang.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: 'landing-top-navbar',
  data: () => ({
    languages: [
      { title: 'EN-English', code: 'en' },
      { title: 'FR-Français', code: 'fr' },
      { title: 'ES-Español', code: 'es' }
    ],
    theme: ''
  }),
  created () {
    this.setLocale()
  },
  methods: {
    switchLang (code) {
      this.$root.$i18n.locale = code
    },
    changeLightTheme () {
      this.$vuetify.theme.dark = false
    },
    changeDarkTheme () {
      this.$vuetify.theme.dark = true
    },
    setLocale () {
      const locale = navigator.language.substring(0, 2)
      this.$root.$i18n.locale = locale
    }
  }
}
</script>
